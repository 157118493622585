import { Fragment } from "react";

import useAccountCreationUserInfo from "./UseAccountCreationUserInfo";

import UserInfoForm from "../../components/forms/UserInfoForm";
import PersonDupPopupManager from "../../components/dialogs/PersonDupPopupManager";

import Headings from "../../../../../../../common/components/headings/Headings";
import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../../../../common/utils/Constants";

const AccountCreationUserInfo = () => {
  const {
    isLoading,
    userInfoData,
    childData,
    invalidEmailPopupState,
    displayInvalidAgePopupState,
    apiErrorPopupState,
    dupCheckState,
    onBackClicked,
    onReturnToLoginClicked,
    onDupCheckPopupCancelClicked,
    onDupCheckPopupContinueClicked,
    onCloseInvalidEmailPopupClicked,
    onCloseAPIErrorPopupClicked,
    onSubmitFormCallback
  } = useAccountCreationUserInfo();

  return (
    <Fragment>
      <div className="usas-extra-bottom-margin">
        <Headings.H3>User Info</Headings.H3>
        <p>Please supply the following information.</p>
      </div>
      <UserInfoForm
        userInfoData={userInfoData}
        childData={childData}
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onBackClicked} />
      {(apiErrorPopupState.displayPopup === false && dupCheckState.isObjLoaded === true && dupCheckState.objData.verificationCode === null && dupCheckState.objData.errorMessage === null) &&
        <PersonDupPopupManager
          dupCheckState={dupCheckState}
          isMemberIdDupCheck={userInfoData?.memberId ? true : false}
          onContinueClicked={onDupCheckPopupContinueClicked}
          onCancelClicked={onDupCheckPopupCancelClicked}
          onReturnToLoginClicked={onReturnToLoginClicked}
          onLinkPersonClicked={onDupCheckPopupContinueClicked} />
      }
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Invalid Age'}
        displayPopUp={displayInvalidAgePopupState === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>You must be over 16 years old in order to create an account. If you are under the required age please have a legal guardian create a parent user account.</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <p>If you are a parent/legal guardian, please view the following article for instructions:</p>
            <p><a href="https://usaswimming.thecloudtutorialusers.com/main/articles/1662596019661" target="_blank" rel="noreferrer">How do I create a parent user account - ARTICLE</a></p>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onReturnToLoginClicked}>Return To Login</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Invalid Email'}
        displayPopUp={invalidEmailPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>The email you entered does not match the email of the existing person.</p>
          </div>
          <div className="col-xs-12">
            <p><b>Entered Email:</b> {invalidEmailPopupState.submittedEmail}</p>
            <p><b>Existing Person Email:</b> {invalidEmailPopupState.dupEmail}</p>
          </div>
          <div className="col-xs-12">
            <p>To continue change your email to {invalidEmailPopupState.dupEmail}. If you no longer have access to that email please contact your LSC or club for support.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseInvalidEmailPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Error'}
        displayPopUp={apiErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{apiErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseAPIErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AccountCreationUserInfo;